<template>
  <mobile-screen :header="true" screen-class="edit-form-screen edit-country">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_countries' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("countries", "edit-country", "edit-country") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      @submit="submitForm"
      ref="editCountryForm"
      v-slot="{ errors }"
      class="form tiny-input edit-form"
      :initial-values="this.selectedCountry && this.selectedCountry[0]"
      novalidate
    >
      <div class="form-group input">
        <label class="tiny-label">
          {{ displayLabelName("countries", "edit-country", "name") }}
        </label>
        <Field
          name="name"
          as="input"
          type="text"
          rules="required"
          :disabled="submitting"
        />
        <span class="error-message" v-if="errors.name">
          {{ errors.name }}
        </span>
      </div>
      <div class="form-group input">
        <label class="tiny-label">
          {{ displayLabelName("countries", "edit-country", "code") }}
        </label>
        <Field
          name="code"
          as="input"
          type="text"
          rules="required"
          :disabled="submitting"
        />
        <span class="error-message" v-if="errors.code">
          {{ errors.code }}
        </span>
      </div>
      <div class="form-group input">
        <label class="tiny-label">
          {{ displayLabelName("countries", "edit-country", "dial-code") }}
        </label>
        <Field
          name="dial_code"
          as="input"
          type="text"
          rules="required"
          :disabled="submitting"
        />
        <span class="error-message" v-if="errors.dial_code">
          {{ errors.dial_code }}
        </span>
      </div>
      <div class="form-group submit">
        <button class="btn rounded light" type="submit" :disabled="submitting">
          {{ displayLabelName("countries", "edit-country", "save-changes") }}
        </button>
      </div>
    </VeeForm>
  </mobile-screen>
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "EditCountry",
  data() {
    return {
      submitting: false
    };
  },
  mounted() {
    if (!this.selectedCountry) {
      this.$router.push({ name: "r_countries" });
    }
  },
  watch: {
    selectedCountry() {
      if (this.selectedCountry) {
        this.selectedCountry.length &&
          this.$refs.editCountryForm.setValues({
            ...this.selectedCountry[0]
          });
      } else {
        this.$refs.editCountryForm.setValues({
          name: "",
          code: "",
          dial_code: ""
        });
      }
    }
  },
  computed: {
    ...mapState("country", ["selectedCountry", "countries"])
  },
  methods: {
    ...mapActions("countries", ["getCountries", "setSelectedCountry"]),
    submitForm(values, { resetForm }) {
      if (values) {
        const data = {
          ...this.selectedCountry[0],
          ...values
        };

        this.submitting = true;
        this.submitMasterData(data, resetForm);
      }
    },
    submitMasterData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.master.countries}/${this.selectedCountry[0].id}`,
          values
        )
        .then(() => {
          resetForm();
          this.getCountries();
          this.setSelectedCountry([
            {
              ...values
            }
          ]);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
